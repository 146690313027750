<template>
  <div data-app>
    <template>
      <v-data-table
        :headers="headers"
        :items="grades"
        sort-by="calories"
        class="elevation-1"
        :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách lớp học
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="addNewItem"
              >Thêm mới</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
<!--        <template v-slot:no-results>-->
<!--          <v-alert :value="true" color="error" icon="warning">-->
<!--            Your search for "{{ search }}" found no results.-->
<!--          </v-alert>-->
<!--        </template>-->
      </v-data-table>
      <!-- DIALOG DELETE  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_LIST_GRADE, DELETE_GRADE } from "@/store/grade.module";
import { validationMixin } from "vuelidate";

import { ERROR } from "@/store/alert.module";
export default {
  mixins: [validationMixin],
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Name", value: "name" },
      { text: "Slug", value: "slug" },
      { text: "Order", value: "order" },
      { text: "Active", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedId: -1,
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters({
      grades: "listGrade",
    }),
  },
  watch: {
    grades: {
      deep: true,
      immediate: true,
      handler: function () {
        this.grades.forEach((grade) => {
          grade.new_img_banner =
            process.env.VUE_APP_BASE_URL + "/" + grade.img_banner;
        });
      },
    },
  },
  mounted() {
    this.getClasses();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lớp học", route: "Lớp học" }]);
  },
  methods: {
    getClasses() {
      this.$store.dispatch(GET_LIST_GRADE);
    },
    editItem(item) {
      this.$router.push({ name: "grade-detail", query: { slug: item.slug } });
    },
    addNewItem() {
      this.$router.push({ name: "grade-detail" });
    },
    deleteItem(item) {
      this.editedIndex = this.grades.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_GRADE, this.editedId).then((data) => {
        if (data.status) {
          this.grades.splice(this.editedIndex, 1);
        } else {
          if(data.errorCode == 424){
            this.$store.dispatch(ERROR, data.message, {root: true});
          }else{
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
